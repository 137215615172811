<template>
  <div class="row mt-5 mr-2 ml-2">
      <h1>Page not found..</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
